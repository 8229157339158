<template>
	<div @mouseleave="$emit('leave')" id="nav-menu" class="mt-header" v-if="activeKey !== ''">
		<div class="container-fluid">
			<div class="row">
				<div class="offset-1 col-md-4 col-lg-3 right-line">
					<ul class="menu-list my-3 py-3">
						<li v-for="(item, key) in activeMenu.items" :key="`${activeKey}-${key}`" class="my-3" @click="$emit('leave')">
							<span class="disabled-link" v-if="item.disabled">{{item.name}}</span>
							<a v-else-if="item.external" :class="`animate__animated animate__fadeIn animate__delay-${key}ms`" :href="item.href" target="_blank">{{item.name}}</a>
							<router-link v-else :class="`animate__animated animate__fadeIn animate__delay-${key}ms`" :to="item.href">{{item.name}}</router-link>
						</li>
					</ul>
				</div>
				<div class="offset-1 col-lg-5 col-md-6 my-3 py-3">
					<h1 :key="`${activeKey}-heading`" class="animate__animated animate__fadeIn">{{ activeMenu.heading }}</h1>
					<h5 :key="`${activeKey}-subheading`" class="animate__animated animate__fadeIn animate__delay-3ms mt-5">{{ activeMenu.subHeading }}</h5>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TopnavMenu",
	props: {
		activeKey:  {
			type: String,
		}
	},
	data() {
		return {
			menuItems: {
				products: {
					heading: "Discover more tools, products and solutions",
					subHeading: "Unleash the power of the world’s leading metaverse platform for creating rich and immersive virtual worlds.",
					items: [
						{name: "BlokHost", href: "/products/blok_host"},
						{name: "LiquifyNFT", href: "/products/liquifynft"},
						{name: "Edit3D", href: "/products/edit3d"},
						{name: "Hourglass", href: "/products/hourglass"},
						// {name: "Metaverse Engine", href: "/products/metaverse_engine", disabled: true},
						{name: "Alpha Wallet", href: "/products/alpha_wallet", disabled: false}
					]
				},
				industries: {
					heading: "Learn more about our products",
					subHeading: "Explore industry use cases around how AlphaBatem Lab's product suite can be deployed in your industry.",
					items: [
						{name: "Metaverse", href: "/industries/metaverse"},
						{name: "Geospatial", href: "/industries/geospatial"},
						{name: "Retail", href: "/industries/retail"},
						{name: "Architecture", href: "/industries/architecture"},
						{name: "Automotive", href: "/industries/automotive"},
						{name: "Digital Signage", href: "/industries/digital_signage"}
					]
				},
				roadmap: {
					heading: "Become a part of our journey on all levels",
					subHeading: "Journey through the metaverse with us. Race the rest of the laps and take every turn with us.",
					items: [
						{name: "Roadmap", href: "/roadmap"},
						{name: "Documentation", href: "https://docs.alphabatem.com", external: true}
					]
				},
				community: {
					heading: "Find your people",
					subHeading: "Find and connect with like minded individuals to discuss, ponder, and celebrate the metaverse together.",
					items: [
						{name: "FAQ", href: "/community/faq"},
						{name: "Blog", href: "https://medium.com/@Hourglass_AB", external: true},
						// {name: "Forums", href: "/", disabled: true},
						// {name: "User Groups", href: "/", disabled: true},
						// {name: "Events", href: "/", disabled: true},
						// {name: "Social Media", href: "/", disabled: true},
						// {name: "Become an Investor", href: "/", disabled: true}
					]
				},
				about: {
					heading: "Understand the insights behind AlphaBatem",
					subHeading: "Discover all you need to know about AlphaBatem to suceed.",
					items: [
						{name: "About AlphaBatem Labs", href: "/about"},
						{name: "Meet Our Team", href: "/about/team"},
						// {name: "Careers", href: "/", disabled: true},
						// {name: "Contributing", href: "/", disabled: true},
						// {name: "Language support", href: "/", disabled: true},
						{name: "Terms of Use", href: "/terms-and-conditions"},
						{name: "Contact Us", href: "/about/contact", disabled: true}
					]
				}
			}
		}
	},
	computed: {
		activeMenu: function() {
			return this.menuItems[this.activeKey]
		}
	}
}
</script>

<style scoped>
#nav-menu {
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	background: transparent;
}

#nav-menu .container-fluid, .right-line {
	min-height: 340px;
}

#nav-menu .container-fluid {
	background: #252525;
}

.mt-header {
	margin-top: 80px;
}

.container-fluid {

	background: #505050;
}

.menu-list {
	list-style: none;
}
.right-line {
	border-right: 1px solid #787878;
}

.disabled-link {
	color: grey;
	cursor: default;
}

</style>