import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
	},
	{
		path: '/products',
		name: 'Products',
		component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
	},
	{
		path: '/products/blok_host',
		name: 'Blok Host',
		component: () => import(/* webpackChunkName: "products" */ '../views/products/BlokHost.vue')
	},
	{
		path: '/products/hourglass',
		name: 'Hourglass',
		component: () => import(/* webpackChunkName: "products" */ '../views/products/Hourglass.vue')
	},
	{
		path: '/products/metaverse_engine',
		name: 'Metaverse Engine',
		component: () => import(/* webpackChunkName: "products" */ '../views/products/MetaverseEngine.vue')
	},
	{
		path: '/products/edit3d',
		name: 'Edit3D',
		component: () => import(/* webpackChunkName: "products" */ '../views/products/AlphaEditor.vue')
	},
	{
		path: '/products/liquifynft',
		name: 'LiquifyNFT',
		component: () => import(/* webpackChunkName: "products" */ '../views/products/LiquifyNFT.vue')
	},
	{
		path: '/products/alpha_wallet',
		name: 'Alpha Wallet',
		component: () => import(/* webpackChunkName: "products" */ '../views/products/AlphaWallet.vue')
	},
	{
		path: '/products/alpha_advertiser',
		name: 'Alpha Advertiser',
		component: () => import(/* webpackChunkName: "products" */ '../views/products/AlphaAdvertiser.vue')
	},
	{
		path: '/industries',
		name: 'Industry Use Cases',
		component: () => import(/* webpackChunkName: "products" */ '../views/Industries.vue')
	},
	{
		path: '/industries/architecture',
		name: 'Use Case  - Architecture',
		component: () => import(/* webpackChunkName: "industries" */ '../views/industries/Architecture.vue')
	},
	{
		path: '/industries/automotive',
		name: 'Use Case  - Automotive',
		component: () => import(/* webpackChunkName: "industries" */ '../views/industries/Automotive.vue')
	},
	{
		path: '/industries/digital_signage',
		name: 'Use Case  - DigitalSignage',
		component: () => import(/* webpackChunkName: "industries" */ '../views/industries/DigitalSignage.vue')
	},
	{
		path: '/industries/geospatial',
		name: 'Use Case  - Geospatial',
		component: () => import(/* webpackChunkName: "industries" */ '../views/industries/Geospatial.vue')
	},
	{
		path: '/industries/metaverse',
		name: 'Use Case  - Metaverse',
		component: () => import(/* webpackChunkName: "industries" */ '../views/industries/Metaverse.vue')
	},
	{
		path: '/industries/retail',
		name: 'Use Case  - Retail',
		component: () => import(/* webpackChunkName: "industries" */ '../views/industries/Retail.vue')
	},
	{
		path: '/industries/training',
		name: 'Use Case  - Training',
		component: () => import(/* webpackChunkName: "industries" */ '../views/industries/Training.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/community/faq',
		name: 'FAQ',
		component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
	},
	{
		path: '/about/team',
		name: 'Team',
		component: () => import(/* webpackChunkName: "team" */ '../views/Team.vue')
	},
	{
		path: '/roadmap',
		name: 'Roadmap',
		component: () => import(/* webpackChunkName: "roadmap" */ '../views/Roadmap.vue')
	},
	{
		path: '/privacy',
		name: 'Privacy Policy',
		component: () => import(/* webpackChunkName: "terms" */ '../views/info/PrivacyPolicy.vue')
	},
	{
		path: '/terms-and-conditions',
		name: 'Terms and Conditions',
		component: () => import(/* webpackChunkName: "terms" */ '../views/info/TermsConditions.vue')
	},
	{
		path: "*",
		redirect: "/"
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				window.scrollTo(0, 0)
				resolve({})
			}, 100)
		})
	},
	routes
})

export default router
