<template>
	<nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
		<div class="container-fluid" @mouseenter="mouseIn = true" @mouseleave="mouseIn = false">
			<router-link class="navbar-brand" to="/">
				<img src="/images/logos/alphabatem.png" height="60" class="d-inline-block align-top" alt="logo">
			</router-link>
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
					aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li class="nav-item px-3" @mouseover="activeMenu = 'products'">
						<router-link to="/products" class="nav-link hover-menu">Products</router-link>
					</li>
					<li class="nav-item px-3" @mouseover="activeMenu = 'industries'">
						<router-link to="/industries" class="nav-link hover-menu">Industries</router-link>
					</li>
					<li class="nav-item px-3" @mouseover="activeMenu = 'roadmap'">
						<router-link to="/roadmap" class="nav-link hover-menu">Roadmap</router-link>
					</li>
					<li class="nav-item px-3" @mouseover="activeMenu = 'community'">
						<span class="nav-link hover-menu">Community</span>
					</li>
					<li class="nav-item px-3" @mouseover="activeMenu = 'about'">
						<router-link to="/about" class="nav-link hover-menu">About</router-link>
					</li>
				</ul>
				<ul class="navbar-nav ms-auto mb-2 mb-lg-0">
					<li class="nav-item px-2">
						<a href="https://hourglassmeta.com" target="_blank" class="nav-link">
							<button class="btn btn-outline-primary">Hourglass Metaverse</button>
						</a>
					</li>
					<li class="nav-item pt-3 me-5">
						<!--						<a target="_blank" href="https://twitter.com/alpha_batem" class="px-2"><img width="32px" alt="" src="/images/icons/header/discord.svg"></a>-->
						<!--						<a target="_blank" href="https://discord.gg/cgKGFQG4jM" class="px-2"><img width="32px" alt="" src="/images/icons/header/twitter.svg"></a>-->
						<!--						<a target="_blank" href="https://www.linkedin.com/company/alphabatem" class="px-2"><img width="32px" alt="" src="/images/icons/header/linkedin.svg"></a>-->

						<a class="px-2" target="_blank" href="https://twitter.com/alpha_batem">
							<div class="d-inline-flex social-link"><img alt="" src="@/assets/images/icon-twitter.png"></div>
						</a>
						<a class="px-2" target="_blank" href="https://www.linkedin.com/company/alphabatem">
							<div class="d-inline-flex social-link"><img alt="" src="@/assets/images/linkedin.svg" width="20"></div>
						</a>
						<a class="px-2" target="_blank" href="https://discord.gg/cgKGFQG4jM">
							<div class="d-inline-flex social-link"><img alt="" src="@/assets/images/icon-discord.png"></div>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<TopnavMenu @leave="closeMenu" :active-key="activeMenu"></TopnavMenu>
	</nav>
</template>

<script>

import TopnavMenu from "./TopnavMenu";

export default {
	name: "Topnav",
	components: {TopnavMenu},
	data() {
		return {
			activeMenu: "",
			mouseIn: false,
		}
	},
	watch: {
		'$route.path': function() {
			console.log("Route change", this.$route.path)
			this.activeMenu = ''
		}
	},
	methods: {
		closeMenu: function () {
			this.$nextTick(() => {
				if (this.mouseIn)
					return

				this.activeMenu = ''
			})
		}
	}
}
</script>

<style scoped>
.navbar-brand, .navbar-dark .navbar-nav .nav-link, .nav-item {
	font-weight: 400;
	color: white;
	transition: color 0.3s ease-in-out;
}

.nav-link:hover, .nav-link:active, .nav-link:focus {
	color: var(--btn-bg-primary) !important;
}

.nav-item .social-link img {
	width: 20px;
	margin: auto;
}

.nav-item .social-link {
	background: white;
	border-radius: 66%;
	padding: 5px;
	width: 36px;
	height: 36px;
}

.nav-item .social-link:hover {
	background: var(--link-primary);
}

.nav-item .social-link:hover img {
	filter: invert(1);
}

.nav-item span {
	cursor: pointer;
}
</style>