import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import store from './store'

import VueToastr from "vue-toastr";

import 'animate.css';
import WOW from "wow.js";

import "@fortawesome/fontawesome-free/js/all"
import "@/assets/css/theme_v2.css"

Vue.config.productionTip = false;

Vue.use(VueToastr, {
    defaultProgressBar: false,
    defaultPosition: "toast-top-right"

    /* OverWrite Plugin Options if you need */
});

new WOW().init();

//TODO Update to new tracking ID for land only
Vue.use(VueGtag, {
    config: {id: 'G-ZDGCCWZ2MM'},
    appName: 'AlphaBatem',
    pageTrackerScreenviewEnabled: true,
}, router)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')